import React from 'react';

import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { Link } from 'src/containers/Link';
import { FixedImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, callouts, customData }) {
  customData = JSON.parse(customData || '{}');
  const logos = callouts.filter((callout) => callout.__typename == 'DatoCmsLogoBlock');

  return (
    <Section {...customData.flags}>
      <h5>{heading}</h5>
      <InlineList {...customData.flags}>
        {logos?.map((logo, i) => {
          // const customData = JSON.parse(logo.customData);
          return (
            <li key={i}>
              <Link to={logo.url}>
                <FixedImg
                  constrain
                  width={Math.round((logo.media.width / logo.media.height) * 30)}
                  height={30}
                  data={logo.media}
                  style={{
                    filter: `brightness(0)`,
                    opacity: 0.4,
                  }}
                />
              </Link>
            </li>
          );
        })}
      </InlineList>
    </Section>
  );
}
